import React, { useRef } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { useScrolledIntoView } from 'utils/hooks/useScrolledIntoView';

import TextTeaser from 'components/common/TextTeaser';
import { PosedTarget } from 'components/common/Posed';

import {
  StageStyled,
  StageImageStyled,
  StageContentStyled,
  BackgroundStyled,
  BackgroundImageStyled,
  StageContentInnerStyled,
  MediaBoxStyled,
  MediaBoxMobileStyled,
  ImageStyled,
  GridHelperStyled,
} from './StageStyled';

function Stage({
  image,
  headline,
  tagline,
  children,
  text,
  buttonLink,
  buttonText,
  backgroundContentImage,
  newsStage,
}) {
  const scrollCheckTarget = useRef(null);
  const [hasScrolledIntoView] = useScrolledIntoView({
    target: scrollCheckTarget,
  });

  return (
    <PosedTarget ref={scrollCheckTarget}>
      <StageStyled>
        <StageContentStyled newsStage={newsStage}>
          <BackgroundStyled>
            <BackgroundImageStyled src={backgroundContentImage} />
          </BackgroundStyled>
          <GridHelperStyled>
            <StageContentInnerStyled newsStage={newsStage}>
              <TextTeaser
                tagline={tagline}
                headline={headline}
                text={text}
                buttonLink={buttonLink}
                buttonText={buttonText}
                hasScrolledIntoView={hasScrolledIntoView}
                textMaxWidth={420}
                isHeadlineLarge
              />

              <MediaBoxMobileStyled>
                {image && (
                  <ImageStyled
                    fluid={image?.[0]}
                    args={{ maxWidth: 800 }}
                    altText={image?.[0]?.altText}
                  />
                )}
              </MediaBoxMobileStyled>
            </StageContentInnerStyled>
            {children}
          </GridHelperStyled>
        </StageContentStyled>

        <StageImageStyled newsStage={newsStage}>
          <MediaBoxStyled>
            {image && (
              <ImageStyled
                fluid={image?.[0]}
                args={{ maxWidth: 1200 }}
                altText={image?.[0]?.altText}
              />
            )}
          </MediaBoxStyled>
        </StageImageStyled>
      </StageStyled>
    </PosedTarget>
  );
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query StageQuery {
        stageContentBackground: file(
          relativePath: { eq: "warsteiner-stage-content-bg.jpg" }
        ) {
          ...imageDefault
        }
      }
    `}
    render={(data) => (
      <Stage backgroundContentImage={data?.stageContentBackground} {...props} />
    )}
  />
);
