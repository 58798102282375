import React from 'react';
import PropTypes from 'prop-types';

import {
  WrapPbSectionStyled,
  WrapPbSectionInnerStyled,
} from './WrapPbSectionStyled';

export default function WrapPbSection({ children, transparentBg }) {
  return (
    <WrapPbSectionStyled transparentBg={transparentBg}>
      <WrapPbSectionInnerStyled>{children}</WrapPbSectionInnerStyled>
    </WrapPbSectionStyled>
  );
}
