import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  RichTextStyled,
  RichTextMoreStyled,
  RichTextMoreButtonStyled,
} from './RichTextStyled';

export default function RichText({ children, moreText, moreTextLabel }) {
  const [textMoreState, setTextMoreState] = useState({ display: false });

  function displayToggle() {
    setTextMoreState({ display: !textMoreState.display });
  }

  return (
    <RichTextStyled element="div">
      {children}
      {moreText && (
        <RichTextMoreStyled
          displayToggle={textMoreState?.display}
          element="div"
        >
          <RichTextMoreButtonStyled onClick={() => displayToggle()}>
            {moreTextLabel}
          </RichTextMoreButtonStyled>
          {moreText}
        </RichTextMoreStyled>
      )}
    </RichTextStyled>
  );
}

RichText.propTypes = {
  moreTextLabel: PropTypes.string,
};

RichText.defaultProps = {
  moreTextLabel: 'weiterlesen',
};
