import media from 'utils/mediaqueries';
import styled from 'utils/emotion';
import { toPx } from 'utils/styles';

import { wrapSizes } from 'components/common/Layout/variables';
import CraftImage from 'components/common/ImageGatsbyCraft';
import Image from 'components/common/Image';

export const GridHelperStyled = styled('div')({
  position: 'relative',
  zIndex: '2',
  [media('tabletFluid')]: {
    marginLeft: '48px',
  },
  [media('laptop')]: {
    marginLeft: `calc(50vw - (${toPx(wrapSizes.laptop - 48)}) / 2)`,
  },
  [media('desktop')]: {
    marginLeft: `calc(50vw - (${toPx(wrapSizes.desktop - 48)}) / 2)`,
  },
});

export const StageStyled = styled('section')({
  width: '100%',

  [media('tabletFluid')]: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: '100vh',
  },
});

export const StageContentStyled = styled('aside')(
  {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    [media('tabletFluid')]: {
      width: '50%',
      overflow: 'inherit',
    },
  },
  (props) =>
    props.newsStage && {
      [media('tabletFluid')]: {
        width: '60%',
      },
    },
);

export const BackgroundStyled = styled('div')({
  position: 'absolute',
  width: '120%',
  height: 'auto',
  top: '0',
  right: '0',
  zIndex: 1,
});

export const BackgroundImageStyled = styled(Image)({
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export const StageContentInnerStyled = styled('div')(
  {
    padding: '0 0 48px 0',

    [media('tablet')]: {
      padding: '0 8% 48px 8%',
    },

    '> div': {
      padding: '0 24px',
      [media('tabletFluid')]: {
        padding: 0,
      },
    },

    [media('tabletFluid')]: {
      paddingTop: '24vh',
      paddingBottom: '6vw',
      paddingLeft: '0',
    },

    [media('laptop')]: {
      paddingTop: '38vh',
      paddingLeft: 0,
    },
  },
  (props) =>
    props.newsStage && {
      paddingTop: '140px!important',
      [media('tabletFluid')]: {
        paddingLeft: '12%',
        paddingTop: '160px!important',
      },
      [media('laptop')]: {
        h2: {
          fontSize: '64px',
          lineHeight: '64px',
        },
      },
    },
);

export const MediaBoxMobileStyled = styled('div')({
  marginTop: '60px',
  [media('tabletFluid')]: {
    display: 'none',
  },
});

export const StageImageStyled = styled('aside')(
  {
    backgroundColor: 'black',
    width: '100%',

    [media('tabletFluid')]: {
      position: 'sticky',
      right: 0,
      top: 0,
      height: '100vh',
      marginBottom: '-100vh',
      willChange: 'transform',
      width: '50%',
    },
  },
  (props) =>
    props.newsStage && {
      [media('tabletFluid')]: {
        position: 'fixed',
        width: '40%',
      },
    },
);

export const MediaBoxStyled = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'none',
  [media('tabletFluid')]: {
    display: 'block',
  },
});

export const ImageStyled = styled(CraftImage)({
  position: 'absolute',
  objectFit: 'cover',
  objectPosition: 'center',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
});
